import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

// Set app element for react-modal
Modal.setAppElement('#root');

const ProteinDetailsPopup = ({ proteinId, onClose }) => {
    const [isOpen, setIsOpen] = useState(true); // State to control modal open/close
    const [proteinDetails, setProteinDetails] = useState(null);
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${BACKEND_URL}/protein/${proteinId}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch protein details');
                }
                const data = await response.json();
                setProteinDetails(data);
            } catch (error) {
                console.error('Error fetching protein details:', error);
            }
        };

        fetchData();
    }, [proteinId]);

    // Handle modal close
    const closeModal = () => {
        setIsOpen(false);
        onClose(); // Callback to parent component to handle modal close
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            className="flex justify-center items-center fixed inset-0 bg-black bg-opacity-50"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            contentLabel="Protein Details"
        >
            <div className="relative bg-white rounded-2xl shadow-2xl w-full max-w-3xl h-[90vh] p-6">
                <button
                    onClick={closeModal}
                    className="absolute top-3 right-3 w-8 h-8 flex items-center justify-center rounded-full bg-red-100 text-red-600 hover:bg-red-200 transition duration-200">
                    &times;
                </button>
                <h2 className="px-4 text-xl md:text-2xl font-semibold text-gray-800 mb-4">Protein Details</h2>
                <div className="overflow-y-auto custom-scrollbar max-h-[70vh] pr-2">

                    {proteinDetails ? (
                        <div>
                            <table className="w-full border-collapse">
                                <tbody>
                                    <tr className="bg-gray-50 hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Protein ID</td>
                                        <td className="px-4 py-2 break-all overflow-hidden whitespace-normal text-sm font-medium text-gray-500">{proteinDetails.PROTEIN}</td>
                                    </tr>
                                    <tr className="hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Full Name</td>
                                        <td className="px-4 py-2 break-all overflow-hidden whitespace-normal text-sm font-medium text-gray-500">
                                            {Array.isArray(proteinDetails.FULL_NAME)
                                                ? proteinDetails.FULL_NAME.map(name => name.trim()).join(', ')
                                                : proteinDetails.FULL_NAME?.trim() || 'Not populated'}
                                        </td>
                                    </tr>
                                    <tr className="bg-gray-50 hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Name</td>
                                        <td className="px-4 py-2 break-all overflow-hidden whitespace-normal text-sm font-medium text-gray-500">{proteinDetails.NAME?.trim() || 'Not populated'}</td>
                                    </tr>
                                    <tr className=" hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Organism Class</td>
                                        <td className="px-4 py-2 break-all overflow-hidden whitespace-normal text-sm font-medium text-gray-500">
                                            {Array.isArray(proteinDetails.ORGANISM_CLASS)
                                                ? proteinDetails.ORGANISM_CLASS.map(item => item.trim()).join(', ')
                                                : proteinDetails.ORGANISM_CLASS?.trim() || 'Not populated'}
                                        </td>
                                    </tr>
                                    <tr className="bg-gray-50 hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Species</td>
                                        <td className="px-4 py-2 break-all overflow-hidden whitespace-normal text-sm font-medium text-gray-500">{proteinDetails.SPECIES?.trim() || 'Not populated'}</td>
                                    </tr>
                                    {/* <tr className="hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Related PubMed IDs</td>
                                        <td className="px-4 py-2 break-all overflow-hidden whitespace-normal text-sm font-medium text-gray-500">{
                                            proteinDetails.RELATED_PUBMED_ID && proteinDetails.RELATED_PUBMED_ID.length > 0
                                                ? proteinDetails.RELATED_PUBMED_ID.map(id => id.trim()).join(', ')
                                                : 'Not populated'
                                        }</td>
                                    </tr> */}
                                    <tr className="hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Related PubMed IDs</td>
                                        <td className="px-4 py-2 break-all overflow-hidden whitespace-normal text-sm font-medium text-gray-500">
                                            {
                                                Array.isArray(proteinDetails.RELATED_PUBMED_ID) && proteinDetails.RELATED_PUBMED_ID.length > 0
                                                    ? proteinDetails.RELATED_PUBMED_ID.map(id => id.trim()).join(', ')
                                                    : proteinDetails.RELATED_PUBMED_ID || 'Not populated'
                                            }
                                        </td>
                                    </tr>

                                    <tr className="bg-gray-50 hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Active Site</td>
                                        <td className="px-4 py-2 break-all overflow-hidden whitespace-normal text-sm font-medium text-gray-500">
                                            {typeof proteinDetails.ACTIVE_SITE === 'object' && proteinDetails.ACTIVE_SITE !== null
                                                ? Object.values(proteinDetails.ACTIVE_SITE)[0] || 'Not populated'
                                                : isNaN(proteinDetails.ACTIVE_SITE) ? 'Not populated' : proteinDetails.ACTIVE_SITE}
                                        </td>
                                    </tr>
                                    <tr className="hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Binding Site</td>
                                        <td className="px-4 py-2 break-all overflow-hidden whitespace-normal text-sm font-medium text-gray-500">
                                            {typeof proteinDetails.BINDING_SITE === 'object' && proteinDetails.BINDING_SITE !== null
                                                ? Object.values(proteinDetails.BINDING_SITE)[0] || 'Not populated'
                                                : isNaN(proteinDetails.BINDING_SITE) ? 'Not populated' : proteinDetails.BINDING_SITE}
                                        </td>
                                    </tr>
                                    <tr className="bg-gray-50 hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Conserved Site</td>
                                        <td className="px-4 py-2 break-all overflow-hidden whitespace-normal text-sm font-medium text-gray-500">
                                            {Array.isArray(proteinDetails.CONSERVED_SITE)
                                                ? proteinDetails.CONSERVED_SITE.map(site => {
                                                    if (typeof site === 'object' && site !== null) {
                                                        return Object.values(site)[0];
                                                    }
                                                    return site;
                                                }).join(', ')
                                                : (typeof proteinDetails.CONSERVED_SITE === 'object' && proteinDetails.CONSERVED_SITE !== null)
                                                    ? Object.values(proteinDetails.CONSERVED_SITE)[0]
                                                    : proteinDetails.CONSERVED_SITE || 'Not populated'}
                                        </td>
                                    </tr>
                                    <tr className="hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Domain</td>
                                        <td className="px-4 py-2 break-all overflow-hidden whitespace-normal text-sm font-medium text-gray-500">
                                            {Array.isArray(proteinDetails.DOMAIN)
                                                ? proteinDetails.DOMAIN.map(item => (
                                                    typeof item === 'object' && item !== null
                                                        ? Object.values(item)[0]
                                                        : item
                                                )).join(', ')
                                                : (typeof proteinDetails.DOMAIN === 'object' && proteinDetails.DOMAIN !== null)
                                                    ? Object.values(proteinDetails.DOMAIN)[0]
                                                    : proteinDetails.DOMAIN || 'Not populated'}
                                        </td>
                                    </tr>
                                    <tr className="bg-gray-50 hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Family</td>
                                        <td className="px-4 py-2 break-all overflow-hidden whitespace-normal text-sm font-medium text-gray-500">
                                            {Array.isArray(proteinDetails.FAMILY)
                                                ? proteinDetails.FAMILY.map(item => (
                                                    typeof item === 'object' && item !== null
                                                        ? Object.values(item)[0]
                                                        : item
                                                )).join(', ')
                                                : (typeof proteinDetails.FAMILY === 'object' && proteinDetails.FAMILY !== null)
                                                    ? Object.values(proteinDetails.FAMILY)[0]
                                                    : proteinDetails.FAMILY || 'Not populated'}
                                        </td>
                                    </tr>
                                    <tr className=" hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">GO Biological Process</td>
                                        <td className="px-4 py-2 break-all overflow-hidden whitespace-normal text-sm font-medium text-gray-500">
                                            {Array.isArray(proteinDetails.GO_BP)
                                                ? proteinDetails.GO_BP.map(item => (
                                                    typeof item === 'object' && item !== null
                                                        ? Object.values(item)[0]
                                                        : item
                                                )).join(', ')
                                                : (typeof proteinDetails.GO_BP === 'object' && proteinDetails.GO_BP !== null)
                                                    ? Object.values(proteinDetails.GO_BP)[0]
                                                    : proteinDetails.GO_BP || 'Not populated'}
                                        </td>
                                    </tr>
                                    <tr className="bg-gray-50 hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">GO Cellular Component</td>
                                        <td className="px-4 py-2 break-all overflow-hidden whitespace-normal text-sm font-medium text-gray-500">
                                            {Array.isArray(proteinDetails.GO_CC)
                                                ? proteinDetails.GO_CC.map(item => (
                                                    typeof item === 'object' && item !== null
                                                        ? Object.values(item)[0]
                                                        : item
                                                )).join(', ')
                                                : (typeof proteinDetails.GO_CC === 'object' && proteinDetails.GO_CC !== null)
                                                    ? Object.values(proteinDetails.GO_CC)[0]
                                                    : proteinDetails.GO_CC || 'Not populated'}
                                        </td>
                                    </tr>
                                    <tr className=" hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">GO Molecular Function</td>
                                        <td className="px-4 py-2 break-all overflow-hidden whitespace-normal text-sm font-medium text-gray-500">
                                            {Array.isArray(proteinDetails.GO_MF)
                                                ? proteinDetails.GO_MF.map(item => (
                                                    typeof item === 'object' && item !== null
                                                        ? Object.values(item)[0]
                                                        : item
                                                )).join(', ')
                                                : (typeof proteinDetails.GO_MF === 'object' && proteinDetails.GO_MF !== null)
                                                    ? Object.values(proteinDetails.GO_MF)[0]
                                                    : proteinDetails.GO_MF || 'Not populated'}
                                        </td>
                                    </tr>
                                    <tr className="bg-gray-50 hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">ID</td>
                                        <td className="px-4 py-2 break-all overflow-hidden whitespace-normal text-sm font-medium text-gray-500">{proteinDetails.ID?.trim() || 'Not populated'}</td>
                                    </tr>
                                    <tr className=" hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">PDB ID</td>
                                        <td className="px-4 py-2 break-all overflow-hidden whitespace-normal text-sm font-medium text-gray-500">{proteinDetails.PDB_ID?.trim() || 'Not populated'}</td>
                                    </tr>
                                    <tr className="bg-gray-50 hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                        <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">RCSB</td>
                                        <td className="px-4 py-2 break-all overflow-hidden whitespace-normal text-sm font-medium text-gray-500">{proteinDetails.RCSB?.trim() || 'Not populated'}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <p className="text-center text-gray-600">Loading protein details...</p>
                    )}
                </div>
            </div>
        </Modal>

    );
};

export default ProteinDetailsPopup;