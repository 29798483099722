import React, { useEffect, useState } from 'react'
import { Tab } from '@headlessui/react';
import MoleculeViewer from './MolecularViewer';
import { useSelector } from 'react-redux';
import DrugDetailsPopup from './DrugDetailsPopup';
import DiseaseDetailsPopup from './DiseaseDetailsPopup';
import { FiClipboard } from 'react-icons/fi';

const ProteinInterface = (data) => {
    const [relatedDrugs, setRelatedDrugs] = useState([]);
    const [relatedDiseases, setRelatedDiseases] = useState([]);
    const [diseaseDetails, setDiseaseDetails] = useState({});
    const [drugDetails, setDrugDetails] = useState({});
    const [drugIdPopup, setDrugIdPopup] = useState(null);
    const [diseaseIdPopup, setDiseaseIdPopup] = useState(null);
    const [open, setOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    const [showAll, setShowAll] = useState(false);
    const RelationsData = useSelector((state) => state.Relations);
    // useEffect(() => {
    //     // Initialize arrays for proteins and diseases
    //     const drugs = [];
    //     const diseases = [];

    //     // Single loop to filter both proteins and diseases
    //     RelationsData.forEach(relation => {
    //         if (relation.TAIL === data.data.PROTEIN && relation.RELATION === "DRUG_TARGET") {
    //             drugs.push(relation);
    //         } else if (relation.HEAD === data.data.PROTEIN && relation.RELATION === "PROTEIN_DISEASE_ASSOCIATION") {
    //             diseases.push(relation);
    //         }
    //     });
    //     setRelatedDrugs(drugs);
    //     setRelatedDiseases(diseases);
    // }, [data.data?.DRUG]);
    useEffect(() => {
        // Fetch proteins and diseases from the API based on the drug data
        const fetchAssociations = async () => {
            try {
                const response = await fetch(`${BACKEND_URL}/relations/associations?protein=${data.data.PROTEIN}`);
                const result = await response.json();
                setRelatedDrugs(result.drugs);
                setRelatedDiseases(result.diseases);
            } catch (error) {
                console.error('Error fetching associations:', error);
            }
        };

        if (data.data.PROTEIN) {
            fetchAssociations();
        }
    }, [data.data.PROTEIN]);
    useEffect(() => {
        const fetchDiseaseDetails = async (diseaseIds) => {
            try {
                const promises = diseaseIds.map(diseaseId =>
                    fetch(`${BACKEND_URL}/disease/${diseaseId}`).then(res => res.json())
                );
                const results = await Promise.all(promises);
                const details = results.reduce((acc, detail, index) => {
                    acc[diseaseIds[index]] = detail;
                    return acc;
                }, {});
                setDiseaseDetails(prevDetails => ({ ...prevDetails, ...details }));
            } catch (error) {
                console.error('Error fetching disease details:', error);
            }
        };

        const newDiseases = relatedDiseases.filter(disease => !diseaseDetails[disease.TAIL]);
        if (newDiseases.length > 0) {
            fetchDiseaseDetails(newDiseases.map(d => d.TAIL));
        }
    }, [relatedDiseases, diseaseDetails]);

    useEffect(() => {
        const fetchDrugDetails = async (drugIds) => {
            try {
                const promises = drugIds.map(drugId =>
                    fetch(`${BACKEND_URL}/drug/${drugId}`).then(res => res.json())
                );
                const results = await Promise.all(promises);
                const details = results.reduce((acc, detail, index) => {
                    acc[drugIds[index]] = detail;
                    return acc;
                }, {});
                setDrugDetails(prevDetails => ({ ...prevDetails, ...details }));
            } catch (error) {
                console.error('Error fetching drug details:', error);
            }
        };

        const newDrugs = relatedDrugs.filter(drug => !drugDetails[drug.HEAD]);
        if (newDrugs.length > 0) {
            fetchDrugDetails(newDrugs.map(d => d.HEAD));
        }
    }, [relatedDrugs, drugDetails]);


    const handleClosePopup = () => {
        setDrugIdPopup(null);
        setDiseaseIdPopup(null);
    };

    // const handleCopyToClipboard = (smiles) => {
    //     navigator.clipboard.writeText(smiles).then(() => {
    //         setSnackbarMessage('SMILES copied to clipboard!');
    //         setOpen(true);
    //     }).catch((err) => {
    //         console.error('Failed to copy: ', err);
    //         setSnackbarMessage('Failed to copy SMILES to clipboard.');
    //         setOpen(true);
    //     });
    // };
    // Handle Load More / Short toggle
    const toggleShowAll = () => {
        setShowAll(prev => !prev); // Toggle the showAll state
    };
    // const tabs = ['Highlights', 'Structure', 'Graph Context', 'Diseases', 'Drugs'];
    const tabs = ['Highlights', 'Structure', 'Diseases', 'Drugs'];
    return (
        <>
            <div className="p-4">
                <h2 className="text-xl font-bold mb-4 text-[#494949]">Protein {data?.data.PROTEIN || 'Details'}</h2>
                <Tab.Group>
                    <Tab.List className="flex p-1 space-x-1 rounded-xl">
                        {tabs.map((tab) => (
                            <Tab
                                key={tab}
                                className={({ selected }) =>
                                    `w-full py-2.5 text-sm leading-5 font-medium focus:outline-none ${selected ? 'text-[#FE4202] border-b-2 border-[#FE4202]' : 'hover:text-[#FE4202]'
                                    }`
                                }
                            >
                                {tab}
                            </Tab>
                        ))}
                    </Tab.List>
                    <Tab.Panels className="mt-2">
                        {tabs.includes('Highlights') && (
                            <Tab.Panel className="bg-white rounded-xl p-3 shadow min-h-[200px] max-h-[600px] overflow-y-auto custom-scrollbar">
                                {/* <h3 className="text-lg font-medium">Highlights</h3> */}
                                <ul className="list-disc pl-5">
                                    <p> <span className='font-semibold'>Full NAME: </span>{data?.data.FULL_NAME || "NA"}</p>
                                    <p> <span className='font-semibold'>Short NAME: </span>{data?.data.NAME || "NA"}</p>
                                    <p> <span className='font-semibold'>Uniprot ID: </span>{data?.data.PROTEIN || "NA"}</p>
                                    {/* <p> <span className='font-semibold'>Related PubMed IDs: </span>{data?.data.RELATED_PUBMED_ID?.join(", ") || "NA"}</p> */}
                                    <p> <span className='font-semibold'>Species: </span>{data?.data.SPECIES || "NA"}</p>
                                    <p> <span className='font-semibold'>GO Biological Process (GO_BP): </span>
                                        {Array.isArray(data?.data.GO_BP)
                                            ? data.data.GO_BP.map(item =>
                                                typeof item === 'object' && item !== null
                                                    ? item["$numberDouble"] || "NA"
                                                    : item || "NA"
                                            ).join(', ')
                                            : (typeof data?.data.GO_BP === 'object' && data.data.GO_BP !== null)
                                                ? data.data.GO_BP["$numberDouble"] || "NA"
                                                : data?.data.GO_BP || "NA"}
                                    </p>
                                    <p> <span className='font-semibold'>GO Cellular Component (GO_CC): </span>
                                        {Array.isArray(data?.data.GO_CC)
                                            ? data.data.GO_CC.map(item =>
                                                typeof item === 'object' && item !== null
                                                    ? item["$numberDouble"] || "NA"
                                                    : item || "NA"
                                            ).join(', ')
                                            : (typeof data?.data.GO_CC === 'object' && data.data.GO_CC !== null)
                                                ? data.data.GO_CC["$numberDouble"] || "NA"
                                                : data?.data.GO_CC || "NA"}
                                    </p>
                                    <p> <span className='font-semibold'>GO Molecular Function (GO_MF): </span>
                                        {Array.isArray(data?.data.GO_MF)
                                            ? data.data.GO_MF.map(item =>
                                                typeof item === 'object' && item !== null
                                                    ? item["$numberDouble"] || "NA"
                                                    : item || "NA"
                                            ).join(', ')
                                            : (typeof data?.data.GO_MF === 'object' && data.data.GO_MF !== null)
                                                ? data.data.GO_MF["$numberDouble"] || "NA"
                                                : data?.data.GO_MF || "NA"}
                                    </p>
                                    <p> <span className='font-semibold'>Homologous Superfamily: </span>
                                        {Array.isArray(data?.data.HOMOLOGOUS_SUPERFAMILY)
                                            ? data.data.HOMOLOGOUS_SUPERFAMILY.map(item =>
                                                typeof item === 'object' && item !== null
                                                    ? item["$numberDouble"] || "NA"
                                                    : item || "NA"
                                            ).join(', ')
                                            : (typeof data?.data.HOMOLOGOUS_SUPERFAMILY === 'object' && data.data.HOMOLOGOUS_SUPERFAMILY !== null)
                                                ? data.data.HOMOLOGOUS_SUPERFAMILY["$numberDouble"] || "NA"
                                                : data?.data.HOMOLOGOUS_SUPERFAMILY || "NA"}
                                    </p>
                                    <p> <span className='font-semibold'>Protein Expressed In: </span>
                                        {Array.isArray(data?.data.PROTEIN_EXPRESSED_IN) ? (
                                            <>
                                                {data.data.PROTEIN_EXPRESSED_IN
                                                    .slice(0, showAll ? undefined : 5) // Limit to 5 items unless 'showAll' is true
                                                    .map((item) =>
                                                        typeof item === 'object' && item !== null
                                                            ? Object.values(item)[0] || "NA"
                                                            : item || "NA"
                                                    ).join(', ')
                                                }
                                                {data.data.PROTEIN_EXPRESSED_IN.length > 5 && (
                                                    <button
                                                        onClick={toggleShowAll} // Toggle 'showAll' state
                                                        className="pl-2 text-blue-600 underline"
                                                    >
                                                        {showAll ? 'Collapse' : 'Load More'}
                                                    </button>
                                                )}
                                            </>
                                        ) : (
                                            typeof data?.data.PROTEIN_EXPRESSED_IN === 'object' && data.data.PROTEIN_EXPRESSED_IN !== null
                                                ? Object.values(data.data.PROTEIN_EXPRESSED_IN)[0] || "NA"
                                                : data?.data.PROTEIN_EXPRESSED_IN || "NA"
                                        )}
                                    </p>
                                </ul>

                            </Tab.Panel>
                        )}
                        {tabs.includes('Structure') && (
                            <Tab.Panel className="bg-white rounded-xl p-3 shadow">
                                <h3 className="text-lg font-medium">Structure (PDB)<span className='font-semibold'>{data?.data.BEST_RES_STRUCTURE_ID || ''}</span></h3>
                                <MoleculeViewer pdb={data?.data.PROTEIN} />
                            </Tab.Panel>
                        )}
                        {/* {tabs.includes('Graph Context') && (
                            <Tab.Panel className="bg-white rounded-xl p-3 shadow">
                                <h3 className="text-lg font-medium">Graph Context</h3>
                                <p>{data.graphContext}</p>
                            </Tab.Panel>
                        )} */}
                        {tabs.includes('Diseases') && (<>
                            <Tab.Panel className="bg-white rounded-xl shadow min-h-[200px] max-h-[400px] overflow-y-auto custom-scrollbar">
                                {relatedDiseases.length > 0 ? (
                                    <table className="min-w-full bg-white border border-gray-200">
                                        <thead className='bg-gray-200'>
                                            <tr className="bg-gray-100 text-left">
                                                <th className="py-2 px-4 border-b">ID</th>
                                                <th className="py-2 px-4 border-b">NAME</th>
                                                <th className="py-2 px-4 border-b">TYPE</th>
                                                {/* <th className="py-2 px-4 border-b">DISEASE_SUPERGRP</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {relatedDiseases.map((disease, index) => {
                                                const details = diseaseDetails[disease.TAIL] || {};
                                                return (
                                                    <tr
                                                        key={index}
                                                        className="hover:bg-gray-50 cursor-pointer"
                                                        onClick={() => setDiseaseIdPopup(disease.TAIL)}
                                                    >
                                                        <td className="py-2 px-4 border-b"><span className="text-blue-600 underline">{disease.TAIL}</span></td>
                                                        <td className="py-2 px-4 border-b">{details.NAME || 'Loading...'}</td>
                                                        <td className="py-2 px-4 border-b">{details.TYPE || 'Loading...'}</td>
                                                        {/* <td className="py-2 px-4 border-b">{details.DISEASE_SUPERGRP?.$numberDouble || 'NA'}</td> */}
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                ) : (
                                    <div className="mt-4 px-4 text-gray-500">
                                        No related diseases available
                                    </div>
                                )}
                            </Tab.Panel>
                        </>
                        )}
                        {tabs.includes('Drugs') && (
                            <Tab.Panel className="bg-white rounded-xl shadow min-h-[200px] max-h-[400px] overflow-y-auto custom-scrollbar">
                                {/* <h3 className="text-lg font-medium">Drugs</h3> */}
                                {relatedDrugs.length > 0 ? (
                                    <table className="min-w-full bg-white border border-gray-200 ">
                                        <thead className='bg-gray-200 z-10'>
                                            <tr className="bg-gray-100 text-left">
                                                <th className="py-2 px-4 border-b">ID</th>
                                                <th className="py-2 px-4 border-b">NAME</th>
                                                <th className="py-2 px-4 border-b">CLASS</th>
                                                {/* <th className="py-2 px-4 border-b">C_SMILE</th> */}
                                                <th className="py-2 px-4 border-b">DIRECT PARENT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {relatedDrugs.map((drug, index) => {
                                                const details = drugDetails[drug.HEAD] || {};
                                                return (
                                                    <tr
                                                        key={index}
                                                        className="hover:bg-gray-50 cursor-pointer"
                                                        onClick={() => setDrugIdPopup(drug.HEAD)}
                                                    >
                                                        <td className="py-2 px-4 border-b"><span className="text-blue-600 underline">{drug.HEAD}</span></td>

                                                        <td className="py-2 px-4 border-b">
                                                            {Array.isArray(details?.NAME)
                                                                ? details.NAME.map(item =>
                                                                    typeof item === 'string' ? item.replace(/(?<!\d)_(?=\D)/g, ' ') : 'NA'  // Ensure item is a string
                                                                ).join(", ")
                                                                : typeof details.NAME === 'string'
                                                                    ? details.NAME.replace(/(?<!\d)_(?=\D)/g, ' ')
                                                                    : 'NA'}
                                                        </td>

                                                        <td className="py-2 px-4 border-b">
                                                            {typeof details.CLASS === 'string'
                                                                ? details.CLASS.replace(/(?<!\d)_(?=\D)/g, ' ')
                                                                : 'NA'}
                                                        </td>

                                                        {/* <td className="py-2 px-4 border-b">{details.C_SMILES || 'Loading...'}</td> */}
                                                        {/* <td className="border px-4 py-2 text-sm text-gray-700"><div className="flex items-center">
                                                        <div className="overflow-x-auto max-w-[150px] whitespace-nowrap custom-scrollbar p-2">
                                                            {details.C_SMILES}
                                                        </div>
                                                        <FiClipboard
                                                            className="ml-2 cursor-pointer text-gray-500 hover:text-gray-800"
                                                            onClick={() => handleCopyToClipboard(details.C_SMILES)}
                                                            title="Copy SMILES"
                                                        />
                                                    </div></td> */}
                                                        <td className="py-2 px-4 border-b">{typeof details.DIRECT_PARENT === 'string'
                                                            ? details.DIRECT_PARENT.replace(/(?<!\d)_(?=\D)/g, ' ') : 'NA'}</td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                ) : (
                                    <div className="mt-4 px-4 text-gray-500">
                                        No related drugs available
                                    </div>
                                )}
                            </Tab.Panel>
                        )}
                    </Tab.Panels>
                </Tab.Group>
            </div>
            {diseaseIdPopup && (
                <DiseaseDetailsPopup className="" diseaseId={diseaseIdPopup} onClose={handleClosePopup} />
            )}
            {drugIdPopup && (
                <DrugDetailsPopup drugId={drugIdPopup} onClose={handleClosePopup} />
            )}
        </>
    )
}

export default ProteinInterface