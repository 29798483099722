import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FiTrash2 } from 'react-icons/fi';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setSelectedConversation } from '../../redux/Actions/action';

const EditedMolecules = () => {
  const [editedMolecules, setEditedMolecules] = useState([]);
  const [deletingMolecule, setDeletingMolecule] = useState(null);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${BACKEND_URL}/auth/get-edited-molecules`, { withCredentials: true })
      .then((response) => {
        if (response.data.status) {
          setEditedMolecules(response.data.editedMolecules);
        }
      })
      .catch((error) => {
        console.error('Error fetching edited molecules:', error);
      });
  }, [BACKEND_URL]);

  const dispatch = useDispatch();
  const handlePredict = (index) => {
    const selectedConversation = editedMolecules[index];
    const conv = editedMolecules[index]
    // console.log(selectedConversation)
    // Dispatch the action to store the conversation in Redux
    dispatch(setSelectedConversation(selectedConversation));
    if (location.pathname === '/profile') {
      navigate('/predictEditedMol', {
        state: { conversation: selectedConversation, from: '/profile' },
      });
    } else {
      navigate('/predictEditedMol', { state: { conversation: selectedConversation} });
    }
  };

  const confirmDelete = (event, conversation) => {
    event.stopPropagation();
    setDeletingMolecule(conversation);
  };

  const handleDelete = () => {
    axios
      .delete(`${BACKEND_URL}/auth/delete-edited-molecule/${deletingMolecule.conversationID}`, {
        withCredentials: true,
      })
      .then((response) => {
        if (response.data.status) {
          setEditedMolecules((prev) =>
            prev.filter((mol) => mol.conversationID !== deletingMolecule.conversationID)
          );
          setDeletingMolecule(null);
        }
      })
      .catch((error) => {
        console.error('Error deleting molecule:', error);
      });
  };

  return (
    <div className="bg-white rounded-xl p-3 shadow min-h-[200px]">
      <h1 className="text-2xl font-semibold mb-4">Edited Molecules</h1>
      {editedMolecules.length === 0 ? (
        <p>No edited molecules found</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 min-h-[100px]">
          {editedMolecules.map((conversation, idx) => (
            <div
              key={idx}
              className="border p-4 rounded shadow-md cursor-pointer"
              onClick={() => handlePredict(idx)}
            >
              <h2 className="text-xl font-semibold mb-2">{conversation.queryName}</h2>
              <p className="font-normal">
                <span className="font-normal" style={{ color: '#FE4202' }}>
                  {conversation.molecules.length}{' '}
                </span>{' '}
                Molecules Edited
              </p>
              <p>Conversation summary title</p>
              <div className="flex justify-between items-center">
                <div className="flex space-x-2">
                  <button
                    className="py-2 pr-2 text-[#FF9783]"
                    onClick={(e) => confirmDelete(e, conversation)}
                    title="Delete"
                  >
                    <FiTrash2 />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {deletingMolecule && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded shadow-md">
            <p className="mb-4">Are you sure you want to delete this molecule?</p>
            <div className="flex justify-end">
              <button
                className="mr-2 px-4 py-2 bg-red-500 text-white rounded"
                onClick={handleDelete}
              >
                Yes
              </button>
              <button
                className="px-4 py-2 bg-gray-300 rounded"
                onClick={() => setDeletingMolecule(null)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditedMolecules;
