import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableView from './TableView';
import AdmetPopup from './AdmetPopup';
import { fetchParameters } from '../../redux/Actions/action';

const DrugProteinPair = ({ data }) => {
    const { drugDetails, proteinDetails } = data || {};
    const [predTempResult, setPredTempResult] = useState('');
    const [predTempAdmet_result, setPredAdmet_result] = useState('');
    const [error, setError] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [selectedAdmet, setSelectedAdmet] = useState(null);
    const [selectedDrugName, setSelectedDrugName] = useState('');

    // Extract top_k and prob_threshold from Redux
    const initialDrugsParameters = useSelector((state) => state.parameters.predictInitialDrugs);

    // Backend URL (from environment variables)
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchParameters()); // Fetch parameters when the component mounts
    }, [dispatch]);

    useEffect(() => {
        if (!data) {
            setError('No data provided');
            return;
        }
        const fetchData = async () => {
            try {
                const topk = initialDrugsParameters.topK || 100; // Default to 100 if not available
                const probThreshold = initialDrugsParameters.probThreshold || 0.6; // Default to 0.6

                const payload = {
                    s_list: [drugDetails?.C_SMILES], // Drug SMILES as list
                    num_cores: 2, // Hardcoded to 2 for now
                    target: proteinDetails?.PROTEIN, // Protein ID
                    top_k: topk, // Extracted from Redux
                    prob_threshold: probThreshold, // Extracted from Redux
                };
                console.log(payload)
                try {
                    const predictResponse = await fetch(`${BACKEND_URL}/auth/api/predict-initial-drugs`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(payload),
                    });

                    if (!predictResponse.ok) {
                        const errorResponse = await predictResponse.json();
                        const errorMessage = errorResponse.error || `HTTP error! Status: ${predictResponse.status}`;
                        throw new Error(errorMessage);
                    }
                    const dataFromBackend = await predictResponse.json();
                    console.log('Data received from backend:', dataFromBackend);

                    // const { predTempResult, predAdmet_result } = predictResponse.data;
                    setPredTempResult(dataFromBackend.predTempResult);
                    setPredAdmet_result(dataFromBackend.predAdmet_result);
                    // console.log('Data received from backend:', dataFromBackend.predTempResult);
                    // console.log('Data received from backend:', dataFromBackend.predAdmet_result);
                } catch (error) {
                    console.error('Error during prediction:', error.message);
                }
            } catch (error) {
                console.error('Error fetching data for predicting drugs:', error);
                setError('Error fetching data for predicting drugs');
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        console.log(predTempResult)
        console.log(predTempAdmet_result)
        if (!predTempResult || !predTempAdmet_result) return;

        const fetchData = async () => {
            try {
                const predictDrugsData = predTempResult.map((item) => ({
                    DRUG__ID: item.DRUG__ID,
                    C_SMILES: item.C_SMILES,
                    PRED: item.PRED
                }));

                // Create a mapping of C_SMILES to admet results
                const admetData = predictDrugsData.reduce((acc, drug, index) => {
                    acc[drug.C_SMILES] = predTempAdmet_result[index] || {};
                    return acc;
                }, {});

                // Map predictDrugsData directly to the required table data structure
                const drugsData = predictDrugsData.map((predictItem) => ({
                    id: drugDetails?.DRUG || 'N/A',
                    smiles: predictItem.C_SMILES,
                    name: drugDetails?.NAME || 'N/A',
                    cov: predictItem.PRED,
                    dock: 'N/A', // Set to 'N/A' or other default value if not available
                    admet: admetData[predictItem.C_SMILES] || {},
                    structure: 'View',
                }));

                console.log('Prepared tableData:', drugsData); // Debugging statement
                setTableData(drugsData);
            } catch (error) {
                console.error('Error preparing data:', error);
            }

        };

        fetchData();
    }, [predTempResult, predTempAdmet_result]);

    return (
        <>
            <div className="p-4">
                {error ? (
                    <div className="text-xl font-bold mb-4 text-gray-400">{error} <span className='pl-2 text-[#494949]'>{data.data.PROTEIN}</span></div>
                ) : (
                    <>
                        <h2 className="text-xl font-bold mb-4 text-[#494949]">Predicted interaction between {drugDetails.DRUG} and {proteinDetails.PROTEIN}</h2>
                        {predTempResult ? (
                            <TableView
                                argument={data}
                                data={tableData}
                                selectable={true}
                                onAdmetClick={(admet, drugName) => {
                                    setSelectedAdmet(admet);
                                    setSelectedDrugName(drugName);
                                }}
                            // onEditSmiles={(smiles) => setEditSmiles(smiles)}
                            // onDockSmiles={(smiles) => setDockSmiles(smiles)}
                            // selectedPdbId={selectedPdbId}
                            />
                        ) : (
                            <div className="text-xl font-bold mb-4 text-gray-300">Loading....</div>
                        )}
                    </>
                )}
            </div>
            {selectedAdmet && (
                <AdmetPopup
                    admet={selectedAdmet}
                    drugName={selectedDrugName}
                    onClose={() => setSelectedAdmet(null)}
                />
            )}
        </>
    );
};


export default DrugProteinPair;