// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import DrugInterface from './DrugInterface';
// import ProteinInterface from './ProteinInterface';
// import PredictDrugInterface from './PredictDrugInterface';
// import PredictProteinsInterface from './PredictProteinsInterface';
// import { useDispatch, useSelector } from 'react-redux';
// import { sessionQueryID, setQueries } from '../../redux/Actions/action';
// import { FaChevronDown } from 'react-icons/fa';
// import PredictNovelDrugsInterface from './PredictNovelDrugsInterface';
// import DrugProteinPair from './DrugProteinPair';

// const RightInterface = ({ messages }) => {
//     const [data, setData] = useState(null);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [latestQuery_ID, setQuery_ID] = useState("");
//     const [interfaceType, setInterfaceType] = useState(null);
//     const [AllQueries, setAllQueries] = useState([]);
//     const [showDropdown, setShowDropdown] = useState(false);
//     const [selectedQuery, setSelectedQuery] = useState(null);
//     const [userSelected, setUserSelected] = useState(false);

//     const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
//     const conv_ID = useSelector((state) => state.conv_ID);

//     // Fetch all queries based on conv_ID
//     const dispatch = useDispatch();
//     useEffect(() => {
//         const fetchQueryIDs = async () => {
//             try {
//                 const response = await axios.get(`${BACKEND_URL}/auth/get-conversation/${conv_ID}`);
//                 const queries = response.data.conversation.queries || [];
//                 setAllQueries(queries);
//                 dispatch(setQueries(AllQueries))

//             } catch (error) {
//                 console.error('Error fetching queryIDs:', error);
//             }
//         };

//         fetchQueryIDs();
//     }, [conv_ID, dispatch, AllQueries]);

//     // Toggle dropdown visibility
//     const toggleDropdown = () => {
//         setShowDropdown(!showDropdown);
//     };

//     // Define the functions to handle different ToolCallResponses
//     const getProteinDetails = async (argument) => {
//         const response = await axios.get(`${BACKEND_URL}/protein/${argument}`);
//         return response.data;
//     };

//     const getDrugDetails = async (argument) => {
//         const response = await axios.get(`${BACKEND_URL}/drug/${argument}`);
//         return response.data;
//     };

//     const predictDrugsForProtein = async (proteinId) => {
//         const response = await axios.get(`${BACKEND_URL}/protein/${proteinId}`);
//         return response.data;
//     };

//     const predictProteinForDisease = async (diseaseId) => {
//         const response = await axios.get(`${BACKEND_URL}/disease/${diseaseId}`);
//         console.log(response)
//         return response.data;
//     };

//     const predictNovelDrugsForProtein = async (proteinId) => {
//         const response = await axios.get(`${BACKEND_URL}/protein/${proteinId}`);
//         return response.data;
//     };

//     const drugProteinPair = async (argument) => {
//         const [drugId, proteinId] = argument; // Destructure the argument array
    
//         try {
//             // Make API requests for drug and protein details
//             const proteinResponse = await axios.get(`${BACKEND_URL}/protein/${proteinId}`);
//             const drugResponse = await axios.get(`${BACKEND_URL}/drug/${drugId}`);
    
//             // Return the combined response data
//             return {
//                 proteinDetails: proteinResponse.data,
//                 drugDetails: drugResponse.data
//             };
//         } catch (error) {
//             console.error('Error fetching data:', error);
//             throw error;
//         }
//     };
//     const retrieveNormalPrompt = (query) => ({ query });

//     const handleToolCallResponse = async (lastMessage) => {
//         if (!lastMessage) return;

//         const { ToolCallResponse, SimpleQuery, Query_ID } = lastMessage;
//         setQuery_ID(Query_ID);

//         // console.log(Query_ID)

//         // Save Query_ID to Redux
//         dispatch(sessionQueryID(Query_ID));

//         if (ToolCallResponse) {
//             const { Function, argument } = ToolCallResponse;
//             setLoading(true);
//             setError(null);
//             setInterfaceType(null);

//             try {
//                 let responseData = null;
//                 if (Function === 'get_protein_details') {
//                     responseData = await getProteinDetails(argument);
//                     setInterfaceType('protein');
//                 } else if (Function === 'get_drug_details') {
//                     responseData = await getDrugDetails(argument);
//                     setInterfaceType('drug');
//                 } else if (Function === 'predict_drugs_for_protein') {
//                     responseData = await predictDrugsForProtein(argument);
//                     setInterfaceType('predict');
//                 } else if (Function === 'show_proteins_for_disease') {
//                     responseData = await predictProteinForDisease(argument);
//                     setInterfaceType('predictProteins');
//                 } else if (Function === 'predict_novel_drugs_for_protein') {
//                     responseData = await predictNovelDrugsForProtein(argument);
//                     setInterfaceType('predictNovel')
//                 } else if (Function === 'drug_protein_pair') {
//                     responseData = await drugProteinPair(argument);
//                     setInterfaceType('drugProteinPair')
//                 } else if (Function === 'retrieve_original_prompt') {
//                     responseData = retrieveNormalPrompt(argument);
//                     setInterfaceType('normal');
//                 }
//                 setData(responseData);
//             } catch (err) {
//                 setError(err);
//             } finally {
//                 setLoading(false);
//             }
//         }
//     };

//     // Extract ToolCallResponse and argument whenever messages change
//     useEffect(() => {
//         handleToolCallResponse(messages[messages.length - 1]);
//         if (messages.length > 0) {
//             setSelectedQuery(messages[messages.length - 1].QueryName)
//         }

//     }, [messages]);


//     // Handle selection from dropdown
//     const handleDropdownSelection = async (selectedQuery, selectedQueryID) => {
//         setShowDropdown(false);

//         // Find the full query data from AllQueries
//         const selectedFullQuery = AllQueries.find(q =>
//             q.QueryName === selectedQuery && q.Query_ID === selectedQueryID
//         );
//         // console.log(selectedFullQuery.QueryName)
//         if (selectedFullQuery) {
//             setSelectedQuery(selectedFullQuery.QueryName); // Update selected query
//             setUserSelected(true);
//             await handleToolCallResponse(selectedFullQuery);
//         }
//     };

//     // useEffect to handle when AllQueries are updated
//     useEffect(() => {
//         if (AllQueries.length > 0 && !userSelected) {
//             const lastQuery = AllQueries[AllQueries.length - 1]; // Get the last query
//             setSelectedQuery(lastQuery.QueryName) // Set the last query as the selected query
//         }
//     }, [AllQueries, userSelected]);

//     if (loading) return <p>Loading...</p>;
//     if (error) return <p>Error loading data: {error.message}</p>;

//     return (
//         <div className='flex'>
//             <div>
//                 {
//                     AllQueries.length > 1 && (
//                         <div>
//                             <p
//                                 className="absolute w-60 right-0 mb-4 px-4 py-2 mx-3 bg-[#EEEFF2] rounded flex justify-between items-center hover:bg-[#DDE0E5] transition duration-200 text-gray-800 font-medium truncate">
//                                 <span className="truncate">{selectedQuery || "Select session"}</span>
//                                 <FaChevronDown title="View Session" onClick={toggleDropdown} className="ml-2 w-4 h-4 text-gray-600 flex-shrink-0 cursor-pointer" />
//                             </p>

//                             {showDropdown && (
//                                 <div className="absolute right-0 mt-10 w-60 mx-3 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5  max-h-[200px] overflow-y-auto custom-scrollbar z-20">
//                                     <div className="" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
//                                         {AllQueries.map((query, index) => (
//                                             <button
//                                                 key={index}
//                                                 className="w-full text-left px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 truncate"
//                                                 role="menuitem"
//                                                 onClick={() => handleDropdownSelection(query.QueryName, query.Query_ID)}
//                                                 title={query.QueryName}
//                                             >
//                                                 {query.QueryName}
//                                             </button>
//                                         ))}
//                                     </div>
//                                 </div>
//                             )}
//                         </div>
//                     )
//                 }
//             </div>

//             <div className='py-4 my-4 w-full'>
//                 {interfaceType === 'drug' && <DrugInterface data={data} />}
//                 {interfaceType === 'protein' && <ProteinInterface data={data} />}
//                 {interfaceType === 'predict' && <PredictDrugInterface data={data} Query_ID={latestQuery_ID} />}
//                 {interfaceType === 'predictProteins' && <PredictProteinsInterface data={data} />}
//                 {/* {interfaceType === 'predictNovel' && <PredictNovelDrugsInterface data={data} />} */}
//                 {interfaceType === 'drugProteinPair' && <DrugProteinPair data={data} />}
//                 {interfaceType === 'normal' && <p className='py-4 my-4'></p>}
//             </div>
//         </div>
//     );
// };

// export default RightInterface;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DrugInterface from './DrugInterface';
import ProteinInterface from './ProteinInterface';
import PredictDrugInterface from './PredictDrugInterface';
import PredictProteinsInterface from './PredictProteinsInterface';
import { useDispatch, useSelector } from 'react-redux';
import { sessionQueryID, setQueries } from '../../redux/Actions/action';
import { FaChevronDown } from 'react-icons/fa';
import PredictNovelDrugsInterface from './PredictNovelDrugsInterface';
import DrugProteinPair from './DrugProteinPair';

const RightInterface = ({ messages }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [latestQuery_ID, setQuery_ID] = useState("");
    const [interfaceType, setInterfaceType] = useState(null);
    const [AllQueries, setAllQueries] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedQuery, setSelectedQuery] = useState(null);
    const [userSelected, setUserSelected] = useState(false);

    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    const conv_ID = useSelector((state) => state.conv_ID);

    // Fetch all queries based on conv_ID
    const dispatch = useDispatch();
    useEffect(() => {
        const fetchQueryIDs = async () => {
            try {
                const response = await axios.get(`${BACKEND_URL}/auth/get-conversation/${conv_ID}`);
                const queries = response.data.conversation.queries || [];
                setAllQueries(queries);
                dispatch(setQueries(AllQueries))

            } catch (error) {
                console.error('Error fetching queryIDs:', error);
            }
        };

        fetchQueryIDs();
    }, [conv_ID, dispatch, AllQueries]);

    // Toggle dropdown visibility
    const toggleDropdown = () => {
        setShowDropdown(!showDropdown);
    };

    // Define the functions to handle different ToolCallResponses
    const getProteinDetails = async (argument) => {
        const response = await axios.get(`${BACKEND_URL}/protein/${argument}`);
        return response.data;
    };

    const getDrugDetails = async (argument) => {
        const response = await axios.get(`${BACKEND_URL}/drug/${argument}`);
        return response.data;
    };

    const predictDrugsForProtein = async (proteinId) => {
        const response = await axios.get(`${BACKEND_URL}/protein/${proteinId}`);
        return response.data;
    };

    const predictProteinForDisease = async (diseaseId) => {
        const response = await axios.get(`${BACKEND_URL}/disease/${diseaseId}`);
        console.log(response)
        return response.data;
    };

    const predictNovelDrugsForProtein = async (proteinId) => {
        const response = await axios.get(`${BACKEND_URL}/protein/${proteinId}`);
        return response.data;
    };

    const drugProteinPair = async (argument) => {
        const [drugId, proteinId] = argument; // Destructure the argument array

        try {
            // Make API requests for drug and protein details
            const proteinResponse = await axios.get(`${BACKEND_URL}/protein/${proteinId}`);
            const drugResponse = await axios.get(`${BACKEND_URL}/drug/${drugId}`);

            // Return the combined response data
            return {
                proteinDetails: proteinResponse.data,
                drugDetails: drugResponse.data
            };
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    };
    const retrieveNormalPrompt = (query) => ({ query });

    const handleToolCallResponse = async (lastMessage) => {
        if (!lastMessage) return;

        const { ToolCallResponse, SimpleQuery, Query_ID } = lastMessage;
        setQuery_ID(Query_ID);

        // console.log(Query_ID)

        // Save Query_ID to Redux
        dispatch(sessionQueryID(Query_ID));

        if (ToolCallResponse) {
            const { Function, argument } = ToolCallResponse;
            setLoading(true);
            setError(null);
            setInterfaceType(null);

            try {
                let responseData = null;
                if (Function === 'get_protein_details') {
                    responseData = await getProteinDetails(argument);
                    setInterfaceType('protein');
                } else if (Function === 'get_drug_details') {
                    responseData = await getDrugDetails(argument);
                    setInterfaceType('drug');
                } else if (Function === 'predict_drugs_for_protein') {
                    responseData = await predictDrugsForProtein(argument);
                    setInterfaceType('predict');
                } else if (Function === 'show_proteins_for_disease') {
                    responseData = await predictProteinForDisease(argument);
                    setInterfaceType('predictProteins');
                } else if (Function === 'predict_novel_drugs_for_protein') {
                    responseData = await predictNovelDrugsForProtein(argument);
                    setInterfaceType('predictNovel')
                } else if (Function === 'drug_protein_pair') {
                    responseData = await drugProteinPair(argument);
                    setInterfaceType('drugProteinPair')
                } else if (Function === 'retrieve_original_prompt') {
                    let previousQueryIndex = AllQueries.length - 1;
                    let previousToolCall = null;
                    console.log(previousQueryIndex)
                    // Iterate backwards to find a valid previous tool call function
                    while (previousQueryIndex >= 0) {
                        const previousQuery = AllQueries[previousQueryIndex];
                        console.log(previousQuery)
                        const previousToolCall = previousQuery?.ToolCallResponse?.Function;
                        const previousArgument = previousQuery?.ToolCallResponse?.argument;
                        console.log(previousToolCall, previousArgument)
                        // Check if the previous tool call function is different
                        if (previousToolCall && previousToolCall !== 'retrieve_original_prompt') {
                            // Call API based on the previous argument and tool call function
                            switch (previousToolCall) {
                                case 'get_protein_details':
                                    responseData = await getProteinDetails(previousArgument);
                                    setInterfaceType('protein');
                                    break;
                                case 'get_drug_details':
                                    responseData = await getDrugDetails(previousArgument);
                                    setInterfaceType('drug');
                                    break;
                                case 'predict_drugs_for_protein':
                                    responseData = await predictDrugsForProtein(previousArgument);
                                    setInterfaceType('predict');
                                    break;
                                case 'show_proteins_for_disease':
                                    responseData = await predictProteinForDisease(previousArgument);
                                    setInterfaceType('predictProteins');
                                    break;
                                case 'predict_novel_drugs_for_protein':
                                    responseData = await predictNovelDrugsForProtein(previousArgument);
                                    setInterfaceType('predictNovel');
                                    break;
                                case 'drug_protein_pair':
                                    responseData = await drugProteinPair(previousArgument);
                                    setInterfaceType('drugProteinPair');
                                    break;
                                default:
                                    console.warn('Unhandled tool call:', previousToolCall);
                                    responseData = retrieveNormalPrompt(argument);
                                    setInterfaceType('normal');
                                    break;
                            }
                            break; // Stop iterating once a valid previous tool call is found
                        }
                        previousQueryIndex--;
                    }


                }
                setData(responseData);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        }
    };

    // Extract ToolCallResponse and argument whenever messages change
    useEffect(() => {
        handleToolCallResponse(messages[messages.length - 1]);
        if (messages.length > 0) {
            setSelectedQuery(messages[messages.length - 1].QueryName)
        }

    }, [messages]);


    // Handle selection from dropdown
    const handleDropdownSelection = async (selectedQuery, selectedQueryID) => {
        setShowDropdown(false);

        // Find the full query data from AllQueries
        const selectedFullQuery = AllQueries.find(q =>
            q.QueryName === selectedQuery && q.Query_ID === selectedQueryID
        );
        // console.log(selectedFullQuery.QueryName)
        if (selectedFullQuery) {
            setSelectedQuery(selectedFullQuery.QueryName); // Update selected query
            setUserSelected(true);
            await handleToolCallResponse(selectedFullQuery);
        }
    };

    // useEffect to handle when AllQueries are updated
    useEffect(() => {
        if (AllQueries.length > 0 && !userSelected) {
            const lastQuery = AllQueries[AllQueries.length - 1]; // Get the last query
            setSelectedQuery(lastQuery.QueryName) // Set the last query as the selected query
        }
    }, [AllQueries, userSelected]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading data: {error.message}</p>;

    return (
        <div className='flex'>
            <div>
                {AllQueries.length > 1 && (
                    <div>
                        <p
                            className="absolute w-60 right-0 mb-4 px-4 py-2 mx-3 bg-[#EEEFF2] rounded flex justify-between items-center hover:bg-[#DDE0E5] transition duration-200 text-gray-800 font-medium truncate">
                            <span className="truncate">
                                {
                                    // Check if selectedQuery is a "normal" one, if so, show "Select session"
                                    AllQueries.find(query => query.QueryName === selectedQuery && query.ToolCallResponse?.Function === 'retrieve_original_prompt')
                                        ? "Select session"
                                        : selectedQuery || "Select session"
                                }
                            </span>
                            <FaChevronDown
                                title="View Session"
                                onClick={toggleDropdown}
                                className="ml-2 w-4 h-4 text-gray-600 flex-shrink-0 cursor-pointer"
                            />
                        </p>

                        {showDropdown && (
                            <div className="absolute right-0 mt-10 w-60 mx-3 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 max-h-[200px] overflow-y-auto custom-scrollbar z-20">
                                <div className="" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                    {AllQueries
                                        .filter(query => query.ToolCallResponse?.Function !== 'retrieve_original_prompt') // Exclude normal queries
                                        .map((query, index) => (
                                            <button
                                                key={index}
                                                className="w-full text-left px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 truncate"
                                                role="menuitem"
                                                onClick={() => handleDropdownSelection(query.QueryName, query.Query_ID)}
                                                title={query.QueryName}
                                            >
                                                {query.QueryName}
                                            </button>
                                        ))}
                                </div>
                            </div>
                        )}
                    </div>
                )}

            </div>

            <div className='py-4 my-4 w-full'>
                {interfaceType === 'drug' && <DrugInterface data={data} />}
                {interfaceType === 'protein' && <ProteinInterface data={data} />}
                {interfaceType === 'predict' && <PredictDrugInterface data={data} Query_ID={latestQuery_ID} />}
                {interfaceType === 'predictProteins' && <PredictProteinsInterface data={data} />}
                {/* {interfaceType === 'predictNovel' && <PredictNovelDrugsInterface data={data} />} */}
                {interfaceType === 'drugProteinPair' && <DrugProteinPair data={data} />}
                {interfaceType === 'normal' && <p className='py-4 my-4'></p>}
            </div>
        </div>
    );
};

export default RightInterface;