import React from 'react';
import { FiLink, FiInfo, FiTarget, FiActivity } from 'react-icons/fi';
import SearchInput from './SearchInput';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

const HomePage = () => {
  const UserData = useSelector((state) => state.UserDetails);

  // useEffect(() => {
  //   dispatch(userDetails());
  //   document.body.style.overflow = 'hidden';
  //   return () => {
  //     document.body.style.overflow = 'auto';
  //   };
  // }, [dispatch]);

  // useEffect(() => {
  //   // Retrieve token from localStorage
  //   const token = localStorage.getItem('token');
  //   // console.log(token)

  //   // If token exists, dispatch userDetails action with the token
  //   if (token) {
  //     dispatch(userDetails(token));
  //   }
  //   document.body.style.overflow = 'hidden';
  //   return () => {
  //     document.body.style.overflow = 'auto';
  //   };
  // }, [dispatch]);

  return (
    <div className='bg-gray-50 h-screen'>
      <div className='justify-between items-center flex px-4 py-4'>
        {/* <p className='text-xl font-bold text-[#5b5b5b]'>covaln</p> */}
        {/* <NavLink to="/useradmin" className='text-xl font-bold text-[#5b5b5b]'>Admin</NavLink> */}
        {/* <NavLink to="/profile" className='text-xl font-bold text-[#5b5b5b]'>profile</NavLink> */}
      </div>
      <div className="flex flex-col items-center bg-gray-50 h-full">
        <div className="flex flex-col items-start mt-20 w-full max-w-4xl px-4">
          <h1 className="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-gradientStart to-gradientEnd">
            {/* Hello, {UserData.username || 'Guest'} */}
            {`Hello, ${UserData?.username?.charAt(0).toUpperCase() + UserData?.username?.slice(1) || 'Guest'}`}
            {/* Hello, {UserData.username.charAt(0).toUpperCase() + UserData.username.slice(1) || 'Guest'} */}
          </h1>
          <h2 className="mt-4 text-3xl font-semibold text-gray-800">Let’s start a new investigation</h2>
        </div>
        <div className="mt-10 grid grid-cols-1 sm:grid-cols-4 gap-2 w-full max-w-4xl px-4 pb-20">
          <button className="flex px-3 py-4 bg-[#EEEFF2] border border-[#959CAB] rounded-lg shadow-sm hover:shadow-md text-left">
            <FiLink className="mr-3 text-gray-500 text-3xl" />
            Compatibility of drug CMLD2 with HuR1
          </button>
          <button className="flex px-3 py-4 bg-[#EEEFF2] border border-[#959CAB] rounded-lg shadow-sm hover:shadow-md text-left">
            <FiInfo className="mr-3 text-gray-500 text-3xl" />
            Fetch me details about this drug DB15960
          </button>
          <button className="flex px-3 py-4 bg-[#EEEFF2] border border-[#959CAB] rounded-lg shadow-sm hover:shadow-md text-left">
            <FiTarget className="mr-3 text-gray-500 text-3xl" />
            Predict drugs when the target for it is EGFR
          </button>
          <button className="flex px-3 py-4 bg-[#EEEFF2] border border-[#959CAB] rounded-lg shadow-sm hover:shadow-md text-left">
            <FiActivity className="mr-3 text-gray-500 text-3xl" />
            What is HuR1 protein, give some details
          </button>
        </div>
        <div className="mt-10 w-full max-w-4xl px-4 py-6 fixed bottom-0 flex justify-center">
          <SearchInput/>
        </div>
      </div>
    </div>
  );
};

export default HomePage;