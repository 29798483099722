import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tab } from '@headlessui/react';
import EditedMolecules from './EditedMolecules';
import Settings from './Settings';

const UserProfilePage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { username, email, name } = useSelector((state) => state.UserDetails);

  const [profileData, setProfileData] = useState({
    name: '',
    username: '',
    email: '',
    image: '',
  });
  const [profileImage, setProfileImage] = useState(null);
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  const initialTab = location.state?.activeTab === 'Edited Molecules' ? 1 : 0;

  useEffect(() => {
    axios
      .get(`${BACKEND_URL}/auth/profile`)
      .then((response) => {
        if (response.data.status) {
          const { name, username, email, image } = response.data.profile;
          setProfileData({
            name: name || '',
            username: username || '',
            email: email || '',
            image: image || '',
          });
        }
      })
      .catch((error) => {
        console.error('Error fetching user profile:', error);
      });
  }, []);

  const handleProfileUpdate = () => {
    const formData = new FormData();
    formData.append('name', profileData.name);

    if (profileImage) {
      formData.append('profileImage', profileImage);
    }

    axios
      .post(`${BACKEND_URL}/auth/update-profile`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        if (response.data.status) {
          console.log('Profile updated successfully:', response.data);
          setProfileData(response.data.profile);
          setProfileImage(null);
        } else {
          console.error('Error in profile update:', response.data.message);
        }
      })
      .catch((error) => {
        console.error('Error updating profile:', error);
      });
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setProfileImage(file);
      const imageUrl = URL.createObjectURL(file);
      setProfileData({ ...profileData, image: imageUrl });
    }
  };

  useEffect(() => {
    return () => {
      if (profileImage) {
        URL.revokeObjectURL(profileData.image);
      }
    };
  }, [profileImage, profileData.image]);

  const tabs = ['User Profile', 'Edited Molecules', 'Settings'];

  return (
    <div className="container mx-auto px-4 my-4">
      <Tab.Group defaultIndex={initialTab}>
        <Tab.List className="flex p-1 space-x-1 rounded-xl">
          {tabs.map((tab) => (
            <Tab
              key={tab}
              className={({ selected }) =>
                `w-full py-2.5 text-sm leading-5 font-medium focus:outline-none ${selected ? 'text-[#FE4202] border-b-2 border-[#FE4202]' : 'hover:text-[#FE4202]'
                }`
              }
            >
              {tab}
            </Tab>
          ))}
        </Tab.List>

        <Tab.Panels className="mt-2">
          {tabs.includes('User Profile') && (
            <Tab.Panel className="bg-white rounded-xl p-3 shadow min-h-[200px]">
              <h1 className="text-2xl font-semibold mb-4">User Profile</h1>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div>
                <img
                  src={
                    profileData.image.startsWith('blob')
                      ? profileData.image
                      : `${BACKEND_URL}${profileData.image}`
                  }
                  alt="Profile"
                  className="rounded-full w-24 h-24 object-cover mb-4"
                />
                  <input type="file" accept="image/*" onChange={handleImageChange} />
                </div>
                <div className="col-span-1 sm:col-span-2 grid grid-cols-1 gap-4">
                  <div>
                    <label className="font-semibold">Name</label>
                    <input
                      type="text"
                      value={profileData.name}
                      className="border rounded px-2 py-1 w-full"
                      onChange={(e) => setProfileData({ ...profileData, name: e.target.value })}
                    />
                  </div>
                  <div>
                    <label className="font-semibold">Username</label>
                    <input
                      type="text"
                      value={profileData.username}
                      className="border rounded px-2 py-1 w-full"
                      disabled
                    />
                  </div>
                  <div>
                    <label className="font-semibold">Email</label>
                    <input
                      type="text"
                      value={profileData.email}
                      className="border rounded px-2 py-1 w-full"
                      disabled
                    />
                  </div>
                  <button
                    className="px-3 py-1 border border-[#FE4202] rounded hover:bg-[#FE4202] hover:border-collapse hover:text-white transition duration-200"
                    onClick={handleProfileUpdate}
                  >
                    Save Changes
                  </button>
                </div>
              </div>
            </Tab.Panel>
          )}

          {tabs.includes('Edited Molecules') && (
            <Tab.Panel>
              <EditedMolecules />
            </Tab.Panel>
          )}

          {tabs.includes('Settings') && (
            <Tab.Panel>
              <Settings />
            </Tab.Panel>
          )}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default UserProfilePage;