import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';

// Set app element for react-modal
Modal.setAppElement('#root');

const DrugDetailsPopup = ({ drugId, onClose }) => {
    const [isOpen, setIsOpen] = useState(true); // State to control modal open/close
    const [drugDetails, setDrugDetails] = useState(null);
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${BACKEND_URL}/drug/${drugId}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch drug details');
                }
                const data = await response.json();
                setDrugDetails(data);
            } catch (error) {
                console.error('Error fetching drug details:', error);
            }
        };

        fetchData();
    }, [drugId]);

    // Handle modal close
    const closeModal = () => {
        setIsOpen(false);
        onClose(); // Callback to parent component to handle modal close
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
            overlayClassName="fixed inset-0 bg-black bg-opacity-50"
            contentLabel="Drug Details"
        >
            <div className="relative bg-white rounded-2xl shadow-2xl w-full max-w-3xl h-[90vh] p-6">
                {/* Close Button */}
                <button
                    onClick={closeModal}
                    className="absolute top-3 right-3 w-8 h-8 flex items-center justify-center rounded-full bg-red-100 text-red-600 hover:bg-red-200 transition duration-200"
                    aria-label="Close Modal"
                >
                    &times;
                </button>

                {/* Title */}
                <h2 className="px-4 text-xl md:text-2xl font-semibold text-gray-800 mb-4">
                    Drug Details
                </h2>

                {/* Drug Details Content */}
                <div className="overflow-y-auto custom-scrollbar max-h-[70vh] pr-2">
                    {drugDetails ? (
                        <table className="w-full border-collapse">
                            {/* <tbody>
                                <tr className="bg-gray-50 hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Name</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{drugDetails.NAME}</td>
                                </tr>
                                <tr className="hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Drug ID</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{drugDetails.DRUG}</td>
                                </tr>
                                <tr className="bg-gray-50 hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Class</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{drugDetails.CLASS}</td>
                                </tr>
                                <tr className="hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Direct Parent</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{drugDetails.DIRECT_PARENT}</td>
                                </tr>
                                <tr className="bg-gray-50 hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Kingdom</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{drugDetails.KINGDOM}</td>
                                </tr>
                                <tr className="hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Product</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{drugDetails.PRODUCT}</td>
                                </tr>
                                <tr className="bg-gray-50 hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">PubMed Article</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{drugDetails.PUBMED_ARTICLE}</td>
                                </tr>
                                <tr className="hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Subclass</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{drugDetails.SUBCLASS}</td>
                                </tr>
                                <tr className="bg-gray-50 hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Superclass</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{drugDetails.SUPERCLASS}</td>
                                </tr>
                                <tr className="hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Synonym</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{drugDetails.SYNONYM}</td>
                                </tr>
                                <tr className="bg-gray-50 hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Type</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{drugDetails.TYPE}</td>
                                </tr>
                                <tr className="hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">ATC Code</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{drugDetails.DRUG_ATC_CODE}</td>
                                </tr>
                                <tr className="bg-gray-50 hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Indication Association</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{drugDetails.DRUG_INDICATION_ASSOCIATION}</td>
                                </tr>
                                <tr className="hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Side Effect Association</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{drugDetails.DRUG_SIDEEFFECT_ASSOCIATION}</td>
                                </tr>
                                <tr className="bg-gray-50 hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">DrugBank Name</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{drugDetails.DRUGBANK_NAME}</td>
                                </tr>
                                <tr className="hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">C_SMILES</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{drugDetails.C_SMILES}</td>
                                </tr>
                                <tr className="bg-gray-50 hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Molecular Weight</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{drugDetails.MolecularWeight}</td>
                                </tr>
                                <tr className="hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">LogP</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{drugDetails.LogP}</td>
                                </tr>
                                <tr className="bg-gray-50 hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Number of Hydrogen Acceptors</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{drugDetails.NumHAcceptors}</td>
                                </tr>
                                <tr className="hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Number of Hydrogen Donors</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{drugDetails.NumHDonors}</td>
                                </tr>
                                <tr className="bg-gray-50 hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">TPSA</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{drugDetails.TPSA}</td>
                                </tr>
                                <tr className="hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Number of Rotatable Bonds</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{drugDetails.NumRotatableBonds}</td>
                                </tr>
                                <tr className="bg-gray-50 hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Lipinski Violation Count</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{drugDetails.LipinskiViolationCount}</td>
                                </tr>
                                <tr className="hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">Source</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{drugDetails.SOURCE}</td>
                                </tr>
                                <tr className="bg-gray-50 hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">DRUGINCHKE</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{drugDetails.DRUGINCHKE}</td>
                                </tr>
                                <tr className="hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">DrugComp</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{drugDetails.DRUGCOMP}</td>
                                </tr>
                                <tr className="bg-gray-50 hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">DRUGINKE</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{drugDetails.DRUGINKE}</td>
                                </tr>
                                <tr className="hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">CompClas</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{drugDetails.COMPCLAS}</td>
                                </tr>
                                <tr className="bg-gray-50 hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-700">INDEX</td>
                                    <td className="px-4 py-2 whitespace-nowrap text-sm font-medium text-gray-500">{drugDetails.INDEX}</td>
                                </tr>
                            </tbody> */}
                            <tbody>
                                {[
                                    { label: 'Name', value: drugDetails.NAME },
                                    { label: 'Drug ID', value: drugDetails.DRUG },
                                    { label: 'Class', value: drugDetails.CLASS },
                                    { label: 'Direct Parent', value: drugDetails.DIRECT_PARENT },
                                    { label: 'Kingdom', value: drugDetails.KINGDOM },
                                    { label: 'Product', value: drugDetails.PRODUCT },
                                    { label: 'PubMed Article', value: drugDetails.PUBMED_ARTICLE },
                                    { label: 'Subclass', value: drugDetails.SUBCLASS },
                                    { label: 'Superclass', value: drugDetails.SUPERCLASS },
                                    { label: 'Synonym', value: drugDetails.SYNONYM },
                                    { label: 'Type', value: drugDetails.TYPE },
                                    { label: 'ATC Code', value: drugDetails.DRUG_ATC_CODE },
                                    { label: 'Indication Association', value: drugDetails.DRUG_INDICATION_ASSOCIATION },
                                    { label: 'Side Effect Association', value: drugDetails.DRUG_SIDEEFFECT_ASSOCIATION },
                                    { label: 'DrugBank Name', value: drugDetails.DRUGBANK_NAME },
                                    { label: 'C_SMILES', value: drugDetails.C_SMILES },
                                    { label: 'Molecular Weight', value: drugDetails.MolecularWeight },
                                    { label: 'LogP', value: drugDetails.LogP },
                                    { label: 'Hydrogen Acceptors', value: drugDetails.NumHAcceptors },
                                    { label: 'Hydrogen Donors', value: drugDetails.NumHDonors },
                                    { label: 'TPSA', value: drugDetails.TPSA },
                                    { label: 'Rotatable Bonds', value: drugDetails.NumRotatableBonds },
                                    { label: 'Lipinski Violation', value: drugDetails.LipinskiViolationCount },
                                    { label: 'Source', value: drugDetails.SOURCE },
                                    { label: 'DRUGINCHKE', value: drugDetails.DRUGINCHKE },
                                    { label: 'DrugComp', value: drugDetails.DRUGCOMP },
                                    { label: 'DRUGINKE', value: drugDetails.DRUGINKE },
                                    { label: 'CompClas', value: drugDetails.COMPCLAS },
                                    { label: 'INDEX', value: drugDetails.INDEX },
                                ].map((item, index) => (
                                    <tr key={index} className="even:bg-gray-50 hover:bg-gray-200 cursor-pointer transition-colors duration-200">
                                        <td className="px-4 py-2 text-sm font-medium text-gray-700">{item.label}</td>
                                        <td className="px-4 py-2 text-sm text-gray-600 ">
                                            {item.value || <span className="italic text-gray-400">Not populated</span>}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>

                        </table>
                    ) : (
                        <div className="text-center text-gray-600">Loading drug details...</div>
                    )}
                </div>
            </div>
        </Modal>
    );
};

export default DrugDetailsPopup;