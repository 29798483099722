import React, { useState, useEffect } from 'react';
import TableView from './TableView';
import AdmetPopup from './AdmetPopup';
import MoleculeEditorPopup from './MoleculeEditorPopup';
import OCL from 'openchemlib';
import { useDispatch, useSelector } from 'react-redux';
import { fetchParameters, userDetails } from '../../redux/Actions/action';
import axios from 'axios';
import DockingPopup from './DockingPopup';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const PredictNovelDrugsInterface = (data) => {
  const [tableData, setTableData] = useState([]);
  const [selectedAdmet, setSelectedAdmet] = useState(null);
  const [selectedDrugName, setSelectedDrugName] = useState('');
  const [editSmiles, setEditSmiles] = useState(null);
  const [dockSmiles, setDockSmiles] = useState(null);
  const [error, setError] = useState(null);
  const [responseMessage, setResponseMessage] = useState('');
  const [predTempResult, setPredTempResult] = useState('');
  const [predTempAdmet_result, setPredAdmet_result] = useState('');
  const [selectedPdbId, setSelectedPdbId] = useState('')

  const conv_ID = useSelector((state) => state.conv_ID);
  const UserData = useSelector((state) => state.UserDetails);
  const novelParameters = useSelector((state) => state.parameters.predictNovelDrugs);
  const dockParameters = useSelector((state) => state.parameters.dock);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchParameters()); // Fetch parameters when the component mounts
  }, [dispatch]);

  useEffect(() => {
    dispatch(userDetails());
  }, [dispatch]);

  useEffect(() => {
    if (!data) {
      setError('No data provided');
      return;
    }
    const fetchData = async () => {
      try {
        // Assigning parameters with default values using logical OR (||)
        const iterations = novelParameters.iterations || 1;
        const topK = novelParameters.topK || 100;
        const seedProbThreshold = novelParameters.seedProbThreshold || 0.75;
        const iterProbThreshold = novelParameters.iterProbThreshold || 0.7;
        const simSearchThreshold = novelParameters.simSearchThreshold || 0.7;
        const simSearchWorkers = novelParameters.simSearchWorkers || 1;

        // const iterations =  1;
        // const topK = 100;
        // const seedProbThreshold =  0.75;
        // const iterProbThreshold = 0.7;
        // const simSearchThreshold =  0.7;
        // const simSearchWorkers = 1;


        // console.log({ iterations, topK, seedProbThreshold, iterProbThreshold, simSearchThreshold, simSearchWorkers });

        // Fetch the relations based on the protein
        const response = await axios.get(`${BACKEND_URL}/relations/${data.data.PROTEIN}`);
        if (response.data.found) {
          const payload = {
            target: data.data.PROTEIN,
            num_iterations: iterations,
            top_k: topK,
            seed_prob_threshold: seedProbThreshold,
            iter_prob_threshold: iterProbThreshold,
            sim_search_threshold: simSearchThreshold,
            sim_search_workers: simSearchWorkers,
          };
          console.log(payload)
          try {
            // Call your server-side API, which will forward the request to port 5000
            const predictResponse = await axios.post(`${BACKEND_URL}/auth/api/predict-novelDrugs`, payload);

            const { predTempResult, predAdmet_result } = predictResponse.data;

            setPredTempResult(predTempResult);
            setPredAdmet_result(predAdmet_result);

            setResponseMessage('Request successful!');

          } catch (error) {
            setResponseMessage(`Request failed. Error: ${error.message}`);
            // console.log(`Request failed. Error: ${error.message}`);
          }
        } else {
          setError(`No predicted drugs for the given target`);
          return;
        }
      } catch (error) {
        console.error('Error fetching data for predicting drugs:', error);
        setError('Error fetching data for predicting drugs');
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // console.log(predTempResult)
    // console.log(predTempAdmet_result)
    if (!predTempResult || !predTempAdmet_result) return;

    const fetchData = async () => {
      try {
        const predictDrugsData = predTempResult.map((item) => ({
          ID: item.MCULE_ID,
          C_SMILES: item.C_SMILES,
          PRED: item.PRED
        }));

        // Create a mapping of C_SMILES to admet results
        const admetData = predictDrugsData.reduce((acc, drug, index) => {
          acc[drug.C_SMILES] = predTempAdmet_result[index] || {};
          return acc;
        }, {});

        // Map predictDrugsData directly to the required table data structure
        const drugsData = predictDrugsData.map((predictItem) => ({
          id: predictItem.ID || 'N/A',
          smiles: predictItem.C_SMILES,
          truncatedSmiles:
            predictItem.C_SMILES.length > 10
              ? `${predictItem.C_SMILES.substring(0, 10)}...`
              : predictItem.C_SMILES,
          // name: 'Ndhf', // Set to 'N/A' or other default value if not available
          cov: predictItem.PRED,
          dock: 'N/A', // Set to 'N/A' or other default value if not available
          admet: admetData[predictItem.C_SMILES] || {},
          structure: 'View',
        }));

        console.log('Prepared tableData:', drugsData); // Debugging statement
        setTableData(drugsData);
      } catch (error) {
        console.error('Error preparing data:', error);
      }

    };

    fetchData();
  }, [predTempResult, predTempAdmet_result]);

  const handleDockSmiles = async (smiles, selectedPdbId) => {

    // Set dock status to 'Docking' for matching SMILES before making the API call
    const dockingTableData = tableData.map(item => {
      const match = smiles.includes(item.smiles);
      if (match) {
        return {
          ...item,
          dock: 'Docking' // Set dock to 'Docking' while processing
        };
      }
      return item;
    });

    // Update the table to reflect the 'Docking' status
    setTableData(dockingTableData);

    console.log("Docking with SMILES:", smiles);
    console.log("Docking with selected PDB ID:", selectedPdbId);
    console.log("PROTEIN ID:", data.data.PROTEIN);

    const exhaustiveness = dockParameters.exhaustiveness || 8;
    const cpus = dockParameters.cpus || 2;

    // Construct the payload
    const Dock = {
      uniprot_acc: data.data.PROTEIN,
      pdb_id: selectedPdbId,
      exhaustiveness,
      cpus,
      smiles_list: smiles
    };
    const payload = {
      email: UserData.email,
      conversationID: conv_ID,
      queryID: data.Query_ID,
      DockValues: Dock,
    };

    console.log(payload)

    try {
      const response = await fetch(`${BACKEND_URL}/auth/save-dock-result`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      });
      const result = await response.json();
      // Handle the response from the server
      console.log(result)

      // Create an object to store the results for each SMILES
      const dockingData = smiles.map(eachsmile => {
        const filteredResults = result.results.filter(result => result.smiles === eachsmile);

        return {
          // uniprot_acc: filteredResults.length > 0 ? filteredResults[0].uniprot_acc : null,
          // pdb_id: filteredResults.length > 0 ? filteredResults[0].pdb_id : null,
          smiles: eachsmile,
          dockData: filteredResults.map(result => ({
            model: result.model,
            'binding affinity': result['binding affinity'],
            'rmsd l.b.': result['rmsd l.b.'],
            'rmsd u.b.': result['rmsd u.b.'],
          }))
        };
      });

      // Output the docking data
      console.log(dockingData);

      if (result) {
        console.log('Molecule saved successfully');

        // Update tableData again to set dock to 'View' after docking completes
        const updatedTableData = dockingTableData.map(item => {
          const matchData = dockingData.find(dock => dock.smiles === item.smiles);
          if (matchData) {
            return {
              ...item,
              dock: 'View', // Set dock to 'View' after successful docking
              dockResult: matchData.dockData // Include the extra property from dockingData
            };
          }
          return item;
        });

        // Update state with the new table data
        setTableData(updatedTableData);
        // console.log(updatedTableData)
      } else {
        console.error('Error saving molecule:', result.message);

        // // Optionally, reset the dock status in case of an error
        // const resetTableData = dockingTableData.map(item => {
        //     const match = smiles.includes(item.smiles);
        //     if (match) {
        //         return {
        //             ...item,
        //             dock: 'Error' // Indicate error if docking failed
        //         };
        //     }
        //     return item;
        // });
        // setTableData(resetTableData);
      }
    } catch (error) {
      console.error('Error:', error);
    }
    setDockSmiles(null)
  };

  const handleSaveSmiles = async (newSmilesArray = []) => {
    // Ensure newSmilesArray is always an array
    if (!Array.isArray(newSmilesArray) || newSmilesArray.length === 0) {
      console.error("Invalid or empty SMILES array:", newSmilesArray);
      return;
    }

    if (!editSmiles) return;

    // Ensure editSmiles is an array
    const smilesArray = Array.isArray(editSmiles) ? editSmiles : [editSmiles];

    // Debugging statements
    // console.log('Current editSmiles:', editSmiles);
    // console.log('New SMILES to save:', newSmilesArray);
    // console.log('Table data for debugging:', tableData);

    const updatedTableData = tableData.map(item => {
      // Find the index of the item’s smiles in smilesArray
      const matchIndex = smilesArray.findIndex(smiles => smiles === item.smiles);

      if (matchIndex === -1) return item; // No match found, return item unchanged

      // Get the new SMILES value
      const newSmilesValue = newSmilesArray[matchIndex] || item.smiles;

      // Process the new SMILES value
      const molecule = OCL.Molecule.fromSmiles(newSmilesValue);
      const svg = molecule.toSVG(300, 300);

      return {
        ...item,
        // smiles: newSmilesValue || 'N/A',
        // truncatedSmiles: newSmilesValue
        //     ? newSmilesValue.length > 10
        //         ? `${newSmilesValue.substring(0, 10)}...`
        //         : newSmilesValue
        //     : 'N/A',
        editedMoleculeSvg: btoa(svg) // Store the SVG as base64
      };
    });

    setTableData(updatedTableData);
    // console.log(updatedTableData)
    // Prepare the molecule data to send to the backend
    const moleculeArray = smilesArray.map((originalSmiles, index) => {
      const newSmilesValue = newSmilesArray[smilesArray.indexOf(originalSmiles)] || originalSmiles;
      // console.log(newSmilesValue)
      const editedMolecule = updatedTableData.find(item => item.smiles === originalSmiles);
      // console.log(editedMolecule)
      return {
        sourceMoleculeID: editedMolecule?.id || '',
        sourceMoleculeSMILES: originalSmiles,
        editedMoleculeSMILES: newSmilesValue,
        dock: 'N/A'
      };
    });

    // console.log('Prepared payload:', {email: UserData.email,conversationID: conv_ID,queryName: `Predicted drugs for ${data.data.PROTEIN}`,queryID: data.Query_ID,proteinID: data.data.PROTEIN,molecules: moleculeArray,});

    // Call the backend API to save the edited molecule
    try {
      const payload = {
        email: UserData.email,
        conversationID: conv_ID,
        queryName: `Predicted drugs for ${data.data.PROTEIN}`,
        queryID: data.Query_ID,
        proteinID: data.data.PROTEIN,
        molecules: moleculeArray,
      };
      // console.log(payload)
      const response = await fetch(`${BACKEND_URL}/auth/save-edited-molecule`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      });

      const result = await response.json();
      if (result.status) {
        console.log('Molecule saved successfully');
      } else {
        console.error('Error saving molecule:', result.message);
      }
    } catch (error) {
      console.error('Error:', error);
    }

    setEditSmiles(null);
  };

  return (
    <>
      <div className="p-4">
        {error ? (
          <div className="text-xl font-bold mb-4 text-gray-400">{error} <span className='pl-2 text-[#494949]'>{data.data.PROTEIN}</span></div>
        ) : (
          <>
            <h2 className="text-xl font-bold mb-4 text-[#494949]">Predicted novel drugs for {data.data.PROTEIN}</h2>
            {predTempResult ? (
              <TableView
                argument={data}
                data={tableData}
                selectable={true}
                onAdmetClick={(admet, drugName) => {
                  setSelectedAdmet(admet);
                  setSelectedDrugName(drugName);
                }}
                onEditSmiles={(smiles) => setEditSmiles(smiles)}
                onDockSmiles={(smiles) => setDockSmiles(smiles)}
                selectedPdbId={selectedPdbId}
              />
            ) : (
              <div className="text-xl font-bold mb-4 text-gray-300">Loading....</div>
            )}
          </>
        )}
      </div>

      {selectedAdmet && (
        <AdmetPopup
          admet={selectedAdmet}
          drugName={selectedDrugName}
          onClose={() => setSelectedAdmet(null)}
        />
      )}

      {/* {(editSmiles && Array.isArray(editSmiles)) && (
                <MoleculeEditorPopup
                    smilesList={editSmiles}
                    onSave={(newSmiles) => handleSaveSmiles(newSmiles)} // Inline function
                    onSaveAll={handleSaveSmiles} // Direct function reference
                    onClose={() => setEditSmiles(null)}
                />
            )} */}

      {/* // Rendering the MoleculeEditorPopup with batch editing functionality */}
      {editSmiles && (
        <MoleculeEditorPopup
          smilesList={Array.isArray(editSmiles) ? editSmiles : [editSmiles]} // Ensure smilesList is an array
          onSave={typeof handleSaveSmiles === 'function' ? handleSaveSmiles : () => { }} // Check if handleSaveSmiles is a function
          onSaveAll={(newSmilesArray) => {
            console.log("New SMILES from MoleculeEditorPopup:", newSmilesArray); // Log the updated array
            handleSaveSmiles(newSmilesArray);  // Pass the new array of SMILES for saving
          }}
          onClose={() => setEditSmiles(null)}
        />
      )}

      {dockSmiles && (
        <DockingPopup
          proteinID={data.data.PROTEIN}
          smilesList={Array.isArray(dockSmiles) ? dockSmiles : [dockSmiles]}
          onDock={(smiles, selectedPdbId) => {
            setSelectedPdbId(selectedPdbId);
            handleDockSmiles(smiles, selectedPdbId);
          }}
          onClose={() => setDockSmiles(null)}
        />
      )}
    </>
  );
};

export default PredictNovelDrugsInterface