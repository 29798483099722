import React, { useState, useEffect, useContext } from 'react';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { PiFlask } from 'react-icons/pi';
import { HiOutlineCog6Tooth } from 'react-icons/hi2';
import { IoPower } from 'react-icons/io5';
import { ChevronFirst, ChevronLast, MoreVertical } from 'lucide-react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { updateConvID, userDetails } from '../redux/Actions/action';
import logo from '../Assets/logo.png';
import { SiMoleculer } from "react-icons/si";
import { CgProfile } from "react-icons/cg";
import { useSelector, useDispatch } from 'react-redux';
import { FiTrash2 } from 'react-icons/fi';
import { AuthContext } from '../AuthContext';

const Sidebar = ({ expanded, setExpanded }) => {
  // const [expanded, setExpanded] = useState(false);
  const [conv_ID, setConv_ID] = useState('');
  const [conversations, setConversations] = useState([]);
  const [deletingConversation, setDeletingConversation] = useState(null);
  const [showOptions, setShowOptions] = useState(false); // State to track options visibility
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  // Make sure to extract user properties correctly
  const { username, email, name } = useSelector((state) => state.UserDetails);
  // const token = localStorage.getItem('token');
  const { setIsAuthenticated, token } = useContext(AuthContext);

  useEffect(() => {
    dispatch(userDetails(token));
  }, [dispatch, token]);

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}/auth/get-conversations`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setConversations(response.data.conversations.reverse());
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/login');
        } else {
          console.error('Error fetching conversations:', error);
        }
      }
    };

    fetchConversations();
  }, [token, navigate, BACKEND_URL]);

  const handleNewConversation = () => {
    const newConv_ID = uuidv4().replace(/-/g, '').trim();
    localStorage.setItem('conv_ID', newConv_ID);
    setConv_ID(newConv_ID);
    dispatch(updateConvID(newConv_ID));
    navigate('/home');
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `${BACKEND_URL}/auth/delete-conversation/${deletingConversation.conversationID}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setConversations((prevConversations) =>
        prevConversations.filter(
          (conv) => conv.conversationID !== deletingConversation.conversationID
        )
      );
      setDeletingConversation(null);
      // Redirect to home page after deletion
      navigate('/home');
    } catch (error) {
      console.error('Error deleting conversation:', error);
    }
  };
  const confirmDelete = (event, conversation) => {
    event.stopPropagation();
    setDeletingConversation(conversation);
  };

  const cancelDelete = () => {
    setDeletingConversation(null);
  };

  const handleExistedConv_ID = (existedConv_ID) => {
    dispatch(updateConvID(existedConv_ID));
  };

  const resetConv_ID = () => {
    const logoutConv_ID = uuidv4().replace(/-/g, '').trim();
    dispatch(updateConvID(logoutConv_ID));
  };

  const iconSize = 24;
  const sidebarWidth = expanded ? 'w-60' : 'w-16';

  return (
    <aside className={`fixed top-0 left-0 h-screen ${sidebarWidth} transition-all duration-300 z-50`}>
      <nav className="h-full flex flex-col bg-[#EEEFF2] border-r shadow-sm">
        <div className="p-4 pb-2 flex justify-between items-center">
          <NavLink to="/home" onClick={handleNewConversation}>
            <img
              src={logo}
              alt="logo"
              className={`overflow-hidden transition-all ${expanded ? 'w-32' : 'w-8'}`}
            />
          </NavLink>
          <button
            onClick={() => setExpanded((curr) => !curr)}
            title={expanded ? 'Close Sidebar' : 'Open Sidebar'}
            className={`p-1.5 rounded-lg bg-gray-50 hover:bg-gray-100 ${!expanded && 'mx-auto'}`}
          >
            {expanded ? <ChevronFirst /> : <ChevronLast />}
          </button>
        </div>
        <ul className="flex-1 px-3 pt-1 mt-1">
          <li
            className={`flex items-center gap-4 text-gray-800 hover:bg-gray-300 p-2 rounded cursor-pointer ${!expanded && 'justify-center'
              }`}
            onClick={handleNewConversation}
            title="New Investigation"
          >
            <AiOutlinePlusCircle size={iconSize} />
            {expanded && <span>New Investigation</span>}
          </li>
          <NavLink
            to="/history"
            className={`flex items-center gap-4 text-gray-800 hover:bg-gray-300 p-2 rounded ${!expanded && 'justify-center'
              }`}
            title="All Investigations"
          >
            <PiFlask size={iconSize} />
            {expanded && <span>All Investigations</span>}
          </NavLink>
          <NavLink
            to="/edited"
            className={`flex items-center gap-4 text-gray-800 hover:bg-gray-300 p-2 rounded ${!expanded && 'justify-center'
              }`}
            title="Edited Molecules"
          >
            <SiMoleculer size={iconSize} />
            {expanded && <span>Edited Molecules</span>}
          </NavLink>
          {expanded && (
            <div className='pt-2 mt-2'>
              <span className="text-gray-600 text-sm ">History</span>

              <div className="flex flex-col gap-2 mt-4 custom-scrollbar overflow-y-auto" style={{ maxHeight: '400px' }}>
                {conversations.map((conversation) => {
                  const summary = conversation.Summary && conversation.Summary.length > 0 ? conversation.Summary[0] : {};
                  const truncatedID = summary.history_title ? summary.history_title.substring(0, 16) : 'New Chat';
                  return (
                    <div
                      key={conversation.conversationID}
                      className="flex items-center justify-between gap-4 text-gray-800 p-2 hover:bg-gray-300 rounded"
                    >
                      <NavLink
                        to={`/c/${conversation.conversationID}`}
                        className="flex-1 truncate "
                        onClick={() => handleExistedConv_ID(conversation.conversationID)}
                      >
                        {truncatedID ? `${truncatedID}...` : 'New Chat'}
                      </NavLink>
                      <button
                        className="p-2 text-[#FF9783] cursor-pointer"
                        title='Delete'
                        onClick={(e) => confirmDelete(e, conversation)}
                      >
                        <FiTrash2 />
                      </button>
                    </div>
                  );
                })}
              </div>
              <NavLink to="/history" className="text-blue-500 text-sm">View all</NavLink>
            </div>

          )}
        </ul>
        <div className="relative p-3 border-t flex">
          <img
            onClick={() => setExpanded((curr) => !curr)}
            src={`https://ui-avatars.com/api/?name=${username || 'Guest'}`}
            alt=""
            className="w-10 h-10 rounded-md mx-auto"
          />
          <div
            className={`flex justify-between items-center overflow-hidden transition-all ${expanded ? 'w-52 ml-3' : 'w-0'}`}
          >
            {expanded && (
              <>
                <div className="leading-4">
                  <h4 className="font-semibold">{username || 'Guest'}</h4>
                  <span className="text-xs text-gray-600">{email || 'guest@example.com'}</span>
                </div>
                <button onClick={() => setShowOptions(!showOptions)}>
                  <MoreVertical size={20} />
                </button>
              </>
            )}
          </div>
          {showOptions && expanded && (
            <div className="absolute bottom-14 left-0 w-full bg-white border rounded shadow-lg">
              <NavLink to="/profile" className="block px-4 py-2 text-gray-800 hover:bg-gray-300"
                onClick={() => setShowOptions(false)}>
                <CgProfile size={iconSize} className="inline-block mr-2" />
                Profile
              </NavLink>
              {/* <NavLink to="/settings" className="block px-4 py-2 text-gray-800 hover:bg-gray-300">
                <HiOutlineCog6Tooth size={iconSize} className="inline-block mr-2" />
                Settings
              </NavLink> */}
              <NavLink
                to="/logout"
                onClick={resetConv_ID}
                className="block px-4 py-2 text-gray-800 hover:bg-gray-300"
              >
                <IoPower size={iconSize} className="inline-block mr-2" />
                LogOut
              </NavLink>
            </div>
          )}
        </div>
      </nav>

      {/* Delete Confirmation Popup */}
      {deletingConversation && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75">
          <div className="bg-white p-4 rounded-lg shadow-lg">
            <h3 className="text-lg font-semibold mb-4">Are you sure you want to delete this conversation?</h3>
            <div className="flex justify-end">

              <button
                className="p-2 bg-gray-200 rounded-md mr-2"
                onClick={cancelDelete}
              >
                Cancel
              </button>
              <button
                className="p-2 bg-red-500 text-white rounded-md"
                onClick={handleDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </aside>
  );
};

export default Sidebar;